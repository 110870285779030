@import "../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

$name: '.ServiceMenu';

#{$name} {
    @include media-breakpoint-up(xl) {
        background-color: $sand-light;
    }

    @include media-breakpoint-down(lg) {
        .container-fluid {
            padding-left: 0;
            padding-right: 0;
        }

        .nav-link {
            display: block;
        }

        .dropdown-menu.is-visible {
            position: static;
            width: 100%;
        }
    }

    &-list {
        margin: 0;
        padding: 0;
        list-style: none;

        @include media-breakpoint-down(lg) {
            margin-top: 15px;
            line-height: 2;

            .nav-link {
                padding-left: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
        }

        &Item {
            @include media-breakpoint-up(xl) {
                margin-right: 20px;
            }
        }

        &Link {
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
